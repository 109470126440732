import React, { FunctionComponent, ReactElement } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

export interface INavLinkProps {
  href: string
  children: React.ReactNode
  customClassName?: string
}

const NavLink: FunctionComponent<INavLinkProps> = ({ href, children, customClassName }) => {
  const router = useRouter()

  if (!children) {
    return null;
  }

  const childrenProps = (children as ReactElement).props;
  const activeClass = (router.pathname === href) && ' active' || '';
  const className = `${childrenProps && childrenProps.className || ''}`;

  return <Link href={href}><a className={`${customClassName || className} ${activeClass}`}>{children}</a></Link>
}

export default NavLink
