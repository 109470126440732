import routes from '@utils/routes';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-scroll';
import NextLink from 'next/link';
import useLocale from '@modules/translate/useLocale';
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import getImage from '@helpers/getImage';

interface IFooterLanding {
  wrapper?: boolean;
}

const Component: FunctionComponent<IFooterLanding> = (props) => {
  const [translate, currentLanguage] = useLocale();
  const { wrapper } = props;

  return (
    <footer role="contentinfo">
      <div className="footer-wrap">
        <div className="grid">
          <div className="footer-top">
            <div className="footer-cont">
              <div className="footer-cont__col footer-cont__col_1">
                <div className="footer-logo">
                  <LazyLoadImage
                    width={214}
                    height={43}
                    alt="footer logo"
                    src={`/landing/svg/${wrapper ? translate('logo_dark') : translate('logo_white')}.svg`}
                  />
                </div>
              </div>
              <div className="footer-cont__col footer-cont__col_2">
                <div className="footer-menu">
                  <div className="footer-menu-items">
                    <div className="footer-menu__item">
                      <NextLink href="/about">
                        <a>
                          {translate('header.menu.about')}
                        </a>
                      </NextLink>
                    </div>
                    <div className="footer-menu__item">
                      <NextLink href={routes.public.courses.page}>
                        <a>
                          {translate('header.menu.courses.main')}
                        </a>
                      </NextLink>
                    </div>
                    <div className="footer-menu__item">
                      <NextLink href={routes.public.courses.premier.page}>
                        <a>
                          {translate('header.menu.courses.premiumQMD')}
                        </a>
                      </NextLink>
                    </div>
                  </div>
                  <div className="footer-menu-items">
                    <div className="footer-menu__item">
                      <NextLink href="/faq">
                        <a>
                          {translate('header.menu.faq')}
                        </a>
                      </NextLink>
                    </div>
                    <div className="footer-menu__item">
                      <NextLink href="/blog">
                        <a>
                          {translate('header.menu.blog')}
                        </a>
                      </NextLink>
                    </div>
                    <div className="footer-menu__item">
                      <NextLink href="/contacts">
                        <a>
                          {translate('header.menu.contacts')}
                        </a>
                      </NextLink>
                    </div>
                    <div className="footer-menu__item">
                      <NextLink href="/auth/teacher/sign-up">
                        <a>
                          {translate('footer.menu.careers')}
                        </a>
                      </NextLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-cont__col footer-cont__col_3">
                <div className="footer-contacts">
                  <div className="footer-contacts__item">
                    <div className="footer-contacts-link">
                      <a href={`mailto:${translate('rest.JQQ')}`}>{translate('rest.JQQ')}</a>
                    </div>
                    <div className="footer-contacts-linkdscrp">
                      {translate('footer.info')}
                    </div>
                  </div>
                </div>
                <div className="footer-socs">
                  <>
                    {currentLanguage !== 'ru' ? (
                      <a href={String(translate('social.linkedin'))} target="_blank"
                         className="footer-socs__item">
                        <img src="/landing/svg/footer-soc-ln.svg" alt="ln" />
                      </a>
                    ) : (
                      <a href={String(translate('social.vk'))} target="_blank" className="footer-socs__item">
                        <img src="/landing/svg/footer-soc-vk.svg" alt="vk" />
                      </a>
                    )}
                    <a href={String(translate('social.inst'))} target="_blank"
                       className="footer-socs__item">
                      <img src="/landing/svg/footer-soc-in.svg" alt="ig" />
                    </a>
                    <a href={String(translate('social.fb'))} target="_blank"
                       className="footer-socs__item">
                      <img src="/landing/svg/footer-soc-fb.svg" alt="fb" />
                    </a>
                  </>
                </div>
              </div>
            </div>
            <div className="page-up">
              <Link
                duration={1000}
                to="startPage"
                spy
              >
                <img src="/landing/svg/page-up.svg" alt="pageup" />
              </Link>
            </div>
          </div>
          <div className="footer-pays">
            <div className="footer-cont">
              {currentLanguage !== 'ru' ? (
                <>
                  <div className="footer-pay">
                    <LazyLoadImage
                      width={69}
                      height={28}
                      alt="icon pay system"
                      src="/landing/svg/footer-pay-1.svg"
                    />
                  </div>
                  <div className="footer-pay">
                    <LazyLoadImage
                      width={150}
                      height={28}
                      alt="icon pay system"
                      src="/landing/svg/footer-pay-2_2.svg"
                    />
                  </div>
                  <div className="footer-pay">
                    <LazyLoadImage
                      width={64}
                      height={28}
                      alt="icon pay system"
                      src="/landing/svg/footer-pay-5.svg"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="footer-pay">
                    <LazyLoadImage
                      width={69}
                      height={28}
                      alt="icon pay system"
                      src="/landing/svg/footer-pay-1.svg"
                    />
                  </div>
                  <div className="footer-pay">
                    <LazyLoadImage
                      width={35}
                      height={28}
                      alt="icon pay system"
                      src="/landing/svg/footer-pay-2.svg"
                    />
                  </div>
                  <div className="footer-pay">
                    <LazyLoadImage
                      width={166}
                      height={28}
                      alt="icon pay system"
                      src="/landing/svg/footer-pay-3.svg"
                    />
                  </div>
                  <div className="footer-pay">
                    <LazyLoadImage
                      width={81}
                      height={28}
                      alt="icon pay system"
                      src="/landing/svg/footer-pay-4.svg"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="footer-bot">
            <div className="footer-cont">
              <div className="footer-text">
                <a
                  href={currentLanguage === 'ru' ? '/pdf/politika_konfidencialnosti.pdf' : '/pdf/privacy-policy.pdf'}
                  download className="footer__policy-item" rel="noreferrer noopener"
                  target="_blank"> {translate('PrivacyPolicy')}</a>
              </div>
              <div className="footer-text">
                <a
                  href={currentLanguage === 'ru' ? '/pdf/usloviya_ispolzovaniya.pdf' : '/pdf/terms_&_conditions.pdf'}
                  download className="footer__policy-item" rel="noreferrer noopener"
                  target="_blank"> {translate('Terms&Conditions')}</a>
              </div>
              {currentLanguage !== 'ru' && (
                <div className="footer-text">
                  <a href={'/pdf/cookie-policy.pdf'} download className="footer__policy-item" rel="noreferrer noopener"
                     target="_blank"> {translate('CookiePolicy')}</a>
                </div>
              )}
              {currentLanguage === 'ru' &&
              <div className="footer-text">
                <a href={'/pdf/dogovor_offerta.pdf'} download className="footer__policy-item"
                   rel="noreferrer noopener"
                   target="_blank"> {translate('OfferAgreement')}</a>
              </div>
              }

              {currentLanguage === 'ru' && (
                <>
                  <div className="footer-text">
                    <a
                      href="/pdf/otkaz_ot_otvetstvennosti.pdf"
                      download className="footer__policy-item" rel="noreferrer noopener"
                      target="_blank"> {translate('Disclaimer')}</a>
                  </div>
                  <div className="footer-text">
                    <a
                      href={currentLanguage === 'ru' ? '/pdf/vybor_kursazakazoplatavozvrat.pdf' : '/pdf/description_&_process_of_ordering_goods_and_services_uk.pdf'}
                      download className="footer__policy-item" rel="noreferrer noopener"
                      target="_blank"> {translate('Payment&refund')}</a>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="footer-under">
            <div className="footer-cont">
              <div className="footer-cont__col footer-cont__col_1">
                <div className="footer-text">
                  <span>© {translate('site-title')}, {moment().format('YYYY')}</span>
                </div>
              </div>
              <div className="footer-cont__col footer-cont__col_2">
              </div>
              <div className="footer-cont__col footer-cont__col_3">
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Component;
