import routes from '@utils/routes';
import React, { FunctionComponent, useState, FC, useMemo } from 'react';
import Link from 'next/link';
import Button from '@components/Button';
import NavLink from '@components/NavLink';
import { LandingHeaderProps } from '@components/Landing/Header/redux';
import LanguageSelector from '@components/LanguageSelector';
import useSession from '@hooks/useSessions';
import useLocale from '@modules/translate/useLocale';
import ChangeTranslateButtonComponent from '@components/ChangeTranslate/Button';
import { Popover } from 'antd';
import PremiumBadge from '@components/Badges/Premium';

interface IHeaderButtons {
  mobile: boolean
  logout(): void
  wrapper?: boolean
}

const HeaderButtons: FC<IHeaderButtons> = ({ mobile, logout, wrapper }) => {
  const { user } = useSession();
  const [translate] = useLocale();

  if (user) {
    return (
      <>
        <Button
          link="/private"
          className={`btn_blue-transp  ${mobile ? 'btn_enter btn_header' : 'btn_starts'}`}
        >
          {translate('header.menu.button.personal-area')}
        </Button>
        <Button
          onClick={() => logout()}
          className={`btn_blue-transp ${wrapper ? 'btn_white' : 'btn_black'} ${mobile ? 'btn_starts btn_header' : 'btn_starts'}`}
        >
          {translate('header.menu.button.logout')}
        </Button>
      </>
    )
  }

  return (
    <>
      <Button
        link="/auth/sign-up"
        className={`btn_blue-transp btn_header ${mobile ? 'btn_starts' : 'btn_starts'}`}
      >
        {translate('header.menu.button.registration')}
      </Button>
      <Button
        link="/auth/sign-in"
        className={`btn_blue-transp ${wrapper ? 'btn_white' : 'btn_black'} btn_header ${mobile ? 'btn_enter' : 'btn_starts'}`}
      >
        {translate('header.menu.button.signin')}
      </Button>
    </>
  );
}


const Component: FunctionComponent<LandingHeaderProps> = (props) => {
  const { logout, wrapper } = props;
  const [activeMenu, setActiveMenu] = useState(false);
  const [visibleSubMenu, setVisibleSubMenu] = useState(false);
  const [translate] = useLocale();

  // TODO: auto generate menu
  const essentialMenu = useMemo(() => [
    <Link href={routes.public.courses.accentTraining}><a>{translate('header.menu.courses.subtitle.general-english')}</a></Link>,
    <Link href={routes.public.courses.businessEnglish}><a>{translate('header.menu.courses.subtitle.business-english')}</a></Link>,
    <Link href={routes.public.courses.academicEnglish}><a>{translate('header.menu.courses.subtitle.academic-english')}</a></Link>,
    <Link
      href={routes.public.courses.childrenEnglish}><a>{translate('header.menu.courses.subtitle.young-learner-english')}</a></Link>,
  ], [translate]);

  const premierMenu = useMemo(() => [
    <Link href={routes.public.courses.premier.business}><a>{translate('premium-business.AGE')}</a></Link>,
    <Link href={routes.public.courses.premier.personal}><a>{translate('premium-personal.PQQ')}</a></Link>,
  ], [translate]);

  return (
    <header>
      <div className={`header ${wrapper ? 'header-black' : ''}`} role="banner">
        <Link href="/">
          <a className="header__logo">
            <img src={`/landing/svg/${wrapper ? translate('logo_white') : translate('logo_dark')}.svg`} alt="logo" />
          </a>
        </Link>
        <div className={`header__nav ${activeMenu ? 'show' : ''}`}>
          <NavLink href="/about">
            <span onClick={() => setActiveMenu(false)}>
              {translate('header.menu.about')}
            </span>
          </NavLink>
          {!activeMenu ? (
            <div className="header-float">
              <div className="header-float__nav">
                <NavLink href={routes.public.courses.page}>
                  <span>
                    {translate('header.menu.courses')}
                  </span>
                </NavLink>
              </div>
              <div className={`header-float__items ${visibleSubMenu ? 'header-float__submenu': ''}`}>
                <Popover
                  overlayClassName="header__popover_styles"
                  placement="rightTop"
                  content={essentialMenu}
                  onVisibleChange={setVisibleSubMenu}
                >
                  <div className="header-float-item">
                    <NavLink href={routes.public.courses.page}>
                      <span>
                        {translate('header.menu.courses.main')}
                      </span>
                    </NavLink>
                  </div>
                </Popover>
                <Popover
                  overlayClassName="header__popover_styles"
                  placement="rightTop"
                  content={premierMenu}
                  onVisibleChange={setVisibleSubMenu}
                >
                  <div className="header-float-item">
                    <NavLink href={routes.public.courses.premier.page}>
                      <span>
                        <PremiumBadge indent size="small"/>
                        <span>{translate('header.menu.courses.premium')}</span>
                      </span>
                    </NavLink>
                  </div>
                </Popover>
                <div className="header-float-item">
                  <NavLink href={routes.public.testPreparation}>
                      <span>
                        {translate('category.title.78abd8a6-3510-4140-9efb-f6ebccbe9b10')}
                      </span>
                  </NavLink>
                </div>
              </div>
            </div>
          ) : (
            <>
              <NavLink href={routes.public.courses.page}>
                <span onClick={() => setActiveMenu(false)}>
                  {translate('header.menu.courses.main')}
                </span>
              </NavLink>
              <NavLink href={routes.public.courses.premier.page} customClassName="menu-to-image">
                <div onClick={() => setActiveMenu(false)}>
                  <PremiumBadge size="small" indent className="color-w"/>
                  <span>
                    {translate('header.menu.courses.premium')}
                  </span>
                </div>
              </NavLink>
              <NavLink href={routes.public.testPreparation}>
                <span onClick={() => setActiveMenu(false)}>
                  {translate('category.title.78abd8a6-3510-4140-9efb-f6ebccbe9b10')}
                </span>
              </NavLink>
            </>
          )}
          <NavLink href={routes.public.teachers}>
            <span onClick={() => setActiveMenu(false)}>
              {translate('header.menu.teachers')}
            </span>
          </NavLink>
          <NavLink href="/faq">
            <span onClick={() => setActiveMenu(false)}>
              {translate('header.menu.faq')}
            </span>
          </NavLink>
          <NavLink href="/blog">
            <span onClick={() => setActiveMenu(false)}>
              {translate('header.menu.blog')}
            </span>
          </NavLink>
          <NavLink href={routes.public.courses.corporateEnglish}>
            <span onClick={() => setActiveMenu(false)}>
              {translate('header.menu.courses.subtitle.corporate-english')}
            </span>
          </NavLink>
          <div className="header__show-mobile">
            <HeaderButtons
              wrapper={wrapper}
              logout={logout}
              mobile={false}
            />
          </div>
        </div>
        <div className="header__lang">
          <div className={`header-lang__lable ${wrapper ? 'header-black__language' : ''}`}>
            <LanguageSelector isLanding />
          </div>
        </div>
        <div className="header__buttons switch_translate">
          <ChangeTranslateButtonComponent isLanding />
        </div>
        <div className="header__buttons">
          <HeaderButtons
            wrapper={wrapper}
            logout={logout}
            mobile
          />
        </div>
        <div
          onClick={() => setActiveMenu(!activeMenu)}
          className={`mobmenu-btn mobmenu-btn_burger ${activeMenu ? 'active' : ''}`}
        >
          <div className="burger" />
        </div>
      </div>
    </header>
  );
};

export default Component;
