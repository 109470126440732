import React, { FunctionComponent, useEffect } from 'react';
import LandingHeader from '@components/Landing/Header';
import LandingFooter from '@components/Landing/Footer';
import Aos from 'aos';
import dynamic from 'next/dynamic';
const SeoEditComponent = dynamic(() => import('@components/SeoEditComponent'), { ssr: false });

interface ILandingLayoutProps {
  wrapper?: boolean
}

const LandingLayout: FunctionComponent<React.PropsWithChildren<ILandingLayoutProps>> = (props) => {
  const { children, wrapper } = props;

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <SeoEditComponent />
      <div className="landing">
        <div className={`wrapper ${!!wrapper ? 'wrapper_dark' : ''}`}>
          <LandingHeader wrapper={wrapper} />
          <div className="sections">
            {children}
          </div>
          <LandingFooter wrapper={wrapper} />
        </div>
      </div>
    </>
  );
};

export default LandingLayout;
