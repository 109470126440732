import { ThunkDispatch } from 'redux-thunk';
import { AppStorageType } from '@app-types/appStorageType';
import { AnyAction } from 'redux';
import { WithTranslation } from '@modules/translate/types/withTranslation';
import { logout } from '@modules/auth/actions';
import { saveLanguageUser } from '@commands/translate';

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppStorageType, void, AnyAction>) => ({
  logout: () => dispatch(logout()),
  saveLanguageUser: (code: string) => dispatch(saveLanguageUser(code)),
});

export const mapStateToProps = (state: AppStorageType) => ({
  document: state.auth.document,
});

export type HeaderProps = WithTranslation & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
