import React, { FunctionComponent, useState } from 'react';
import useLocale from '@modules/translate/useLocale';
import { setCookie } from '@modules/common/tools/cookie';
import useSession from '@hooks/useSessions';
import { LandingSelectorProps } from '@components/LanguageSelector/redux';
import buildLangBaseUrl from '@utils/langTool';
import Select from 'react-select';

const LanguageSelector: FunctionComponent<LandingSelectorProps> = (props) => {
  const { saveLanguageUser, isLanding } = props;
  const [, currentLanguage, , langs, setLang] = useLocale();
  const { user } = useSession();
  const [visible, setVisible] = useState(false);

  const changeLanguage = async (lang: string) => {
    if (lang === currentLanguage) {
      return;
    }
    setCookie('langValue', lang as string || 'en');
    user && user.id && await saveLanguageUser(lang as string);

    setLang && setLang(lang as string);

    window.location.href = buildLangBaseUrl(lang);
  };

  if (langs && langs.length < 2) {
    return null;
  }

  return (
    <>
      {isLanding ? <div className="language-selector">
          <div
            onClick={() => setVisible(true)}
            className="language-selector__button"
          >
            <span>{currentLanguage.toUpperCase()}</span>
          </div>
          <div
            className={`language-selector__mask ${visible ? 'language-selector__select_show' : ''}`}
            onClick={() => {
              setVisible(false);
            }}
          />
          <div className={`language-selector__select ${visible ? 'language-selector__select_show' : ''}`}>
            {langs.map((i, index) => (
              <div
                key={`lang-${index}`}
                className="language-selector__item"
                onClick={() => {
                  changeLanguage(i.code);
                  setVisible(false);
                }}
              >
                <span>{i.code.toUpperCase()}</span>
              </div>
            ))}
          </div>
        </div> :
        <Select
          onChange={(e) => e && changeLanguage(e.value)}
          value={{ value: currentLanguage, label: currentLanguage }}
          className="select-box header-button"
          classNamePrefix="select"
          name="time-zone"
          options={langs.map(i => ({ value: i.code, label: i.code }))}
        />}
    </>
  );
};

export default LanguageSelector;
