import React, {
  FunctionComponent,
  ReactElement, useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import Link from 'next/link';

interface IButtonProps {
  children: string | ReactElement
  link?: string
  className?: string
  type?: 'submit' | 'reset' | 'button' | undefined
  onClick?(): void
}

const Button: FunctionComponent<IButtonProps> = (props) => {
  const { children, link, className, type, onClick } = props;
  const [style, setStyle] = useState<object>();
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {if (ref.current) {
    ref.current.addEventListener('mouseover', (e) => {
      if (!ref.current) {
        return;
      }

      const position = ref.current.getBoundingClientRect();
      const x = e.clientX - position.left;
      const y = e.clientY - position.top;
      const w = ref.current?.clientWidth;
      setStyle({ '--x': Math.round(x) + 'px', '--y': Math.round(y) + 'px', '--w': Math.round(w || 0) + 'px' });
    });
  }
  }, [ref && ref.current]);

  return (
    <>
      {!!type || !!onClick ? (
        <a className="wrapper_btn" ref={ref}>
          <button
            type={!!type ? type : undefined}
            className={`btn ${!!className && className}`}
            style={style}
            onClick={() => !!onClick ? onClick() : {}}
          >
            <span>
              {children}
            </span>
          </button>
        </a>
      ) : (
        <Link href={!!link ? link : '/'}>
          <a
            ref={ref}
            className={`btn ${!!className && className}`}
            style={style}
          >
            <span>
              {children}
            </span>
          </a>
        </Link>
      )}
    </>
  );
};

export default Button;
