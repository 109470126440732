import React, { FunctionComponent } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './styles.module.css';
import useLocale from '@modules/translate/useLocale';

interface PremiumBadge {
  size?: 'small' | 'big' | 'middle'
  indent?: boolean
  className?: string
}

const PremiumBadge: FunctionComponent<PremiumBadge> = (props) => {
  const { size = 'middle', indent, className } = props;
  const [translate] = useLocale();

  return (
    <div className={`${styles['wrapper']} ${styles[`wrapper_${size}`]} ${indent && styles[`wrapper_indent`]} ${className}`}>
      <LazyLoadImage
        alt="title label"
        src="/svg/star.svg"
      />
      <span>{translate('badge.premium')}</span>
    </div>
  );
};

export default PremiumBadge;
