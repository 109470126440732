import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getTranslateByKey } from '@commands/translate';
import { AppStorageType } from '@app-types/appStorageType';
import { setEditTranslateAction, setEditTranslateType } from '@modules/translate/actions/actionAdmin';

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppStorageType, void, AnyAction>) => ({
  change: (value: boolean) => dispatch(setEditTranslateAction(value)),
});

export const mapStateToProps = (state: AppStorageType) => ({
  editTranslate: state.translate.admin.editTranslate,
});

interface IComponentProps {
  isLanding?: boolean
}

export type ComponentProps = IComponentProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;
