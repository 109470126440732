import { AppDispatch } from '@app-types/appStorageType';
import { ResultItemRes, ResultListRes } from '@modules/apiClient/type';
import { apiCall } from '@commands/httpClient';
import { ICountryCurrency } from '@modules/country-corrency/types';
import {
  createCountryCurrencyAction, removeCountryCurrencyAction,
  setCountryCurrencyListAction,
  updateCountryCurrencyAction,
} from '@modules/country-corrency/actions';
import { BaseCommandProps } from '@app-types/CommonTypes';

export const loadCountry = () => (dispatch: AppDispatch<ResultListRes<number>>) => {
  dispatch(apiCall<object, ResultListRes<ICountryCurrency[]>>(`/v1/country-currency/list`, undefined, { method: 'get' }))
    .then(({ list }) => {
      dispatch(setCountryCurrencyListAction(list));
    });
};

export const createCountryCurrency = (value: ICountryCurrency) => (dispatch: AppDispatch<ResultItemRes<ICountryCurrency>>) => {
  return dispatch(apiCall<object, ResultItemRes<ICountryCurrency>>(`/v1/country-currency`, value, { method: 'post' }))
    .then(({ item }) => dispatch(createCountryCurrencyAction(item)));
};

export const updateCountryCurrency = (value: ICountryCurrency) => (dispatch: AppDispatch<ResultItemRes<ICountryCurrency>>) => {
  return dispatch(apiCall<object, BaseCommandProps>(`/v1/country-currency`, value, { method: 'put' }))
    .then(() => dispatch(updateCountryCurrencyAction(value)));
};

export const removeCountryCurrency = (id: number) => (dispatch: AppDispatch<number>) => {
  return dispatch(apiCall<object, BaseCommandProps>(`/v1/country-currency`, { id }, { method: 'delete' }))
    .then(() => dispatch(removeCountryCurrencyAction(id)));
};