import React, { FunctionComponent } from 'react';
import { ComponentProps } from './redux';
import { Switch, Tooltip } from 'antd';
import useSession from '@hooks/useSessions';
import useLocale from '@modules/translate/useLocale';

const ChangeTranslateButtonComponent: FunctionComponent<ComponentProps> = (props) => {
  const { change, editTranslate, isLanding } = props;
  const { user } = useSession();
  const [translate] = useLocale();

  if (!user?.isAdmin) {
    return null;
  }

  return (
    <Tooltip title={translate('translate.change.switch.tooltip', undefined, true)}>
      <div style={{ margin: 'auto 10px' }}>
        <Switch
          style={{ background: isLanding && 'silver' || '' }}
          defaultChecked={editTranslate}
          onChange={change}
        />
      </div>
    </Tooltip>
  );
};

export default ChangeTranslateButtonComponent;
