import { AppStorageType } from '@app-types/appStorageType';
import { logout } from '@modules/auth/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

export const mapStateToProps = (state: AppStorageType) => ({
  items: state.information.wikipedia.listTable,
});

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppStorageType, void, AnyAction>) => ({
  logout: () => dispatch(logout()),
});

interface ILandingHeader {
  wrapper?: boolean
}

export type LandingHeaderProps = ILandingHeader & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
