
const routes = {
  public: {
    testPreparation: '/courses/test-preparation-ielts-toefl-toeic',
    testPreparationCode: 'test-preparation-ielts-toefl-toeic',
    teachers: '/tutors',
    courses: {
      page: '/courses/soft-skill-courses',
      accentTraining: '/courses/soft-skill-courses/communication-soft-skills',
      businessEnglish: '/courses/soft-skill-courses/business-soft-skills',
      academicEnglish: '/courses/soft-skill-courses/academic-soft-skills',
      childrenEnglish: '/courses/soft-skill-courses/young-learner-soft-skills',
      corporateEnglish: '/courses/for-organisations-corporate-training',
      premier: {
        page: "/courses/premier",
        business: '/courses/premier/work-abroad',
        personal: '/courses/premier/study-abroad'
      }
    }
  }
}

export default routes;