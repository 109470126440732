import { AppStorageType } from '@app-types/appStorageType';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { saveLanguageUser } from '@commands/translate';
import { loadCountry } from '@commands/country_currency';

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppStorageType, void, AnyAction>) => ({
  saveLanguageUser: (code: string) => dispatch(saveLanguageUser(code)),
  loadCountry: () => dispatch(loadCountry()),
});

export const mapStateToProps = (state: AppStorageType) => ({
  countries: state.country,
});

interface ILandingSelectorProps {
  isLanding: boolean,
}

export type LandingSelectorProps =
  ILandingSelectorProps
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
